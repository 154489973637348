import React from 'react';
import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { GENRE } from 'constants/genre';
import { PATHS } from 'constants/paths';
import JavascriptSvg from 'svgs/blog/javascript.svg';
import ConnectionIcon from 'svgs/icons/ic-cloud-connection.svg';
import EaseIcon from 'svgs/icons/ic-data-vertical.svg';
import VisualisationIcon from 'svgs/icons/ic-document-filter.svg';
import AccessionIcon from 'svgs/icons/ic-monitor-mobile.svg';
import DepthIcon from 'svgs/icons/ic-search-data.svg';
import { graphql, useStaticQuery } from 'gatsby';
import { CASE_STUDIES_TAG } from 'views/case-studies/constants';

export const TILES_DATA = [
    {
        icon: <ConnectionIcon width={42} height={42} />,
        text: 'view-devops-consulting-services.solutions-section.connection.title',
        description:
            'view-devops-consulting-services.solutions-section.connection.desc',
    },
    {
        icon: <EaseIcon width={42} height={42} />,
        text: 'view-devops-consulting-services.solutions-section.ease.title',
        description:
            'view-devops-consulting-services.solutions-section.ease.desc',
    },
    {
        icon: <DepthIcon width={42} height={42} />,
        text: 'view-devops-consulting-services.solutions-section.depth.title',
        description:
            'view-devops-consulting-services.solutions-section.depth.desc',
    },
    {
        icon: <VisualisationIcon width={42} height={42} />,
        text: 'view-devops-consulting-services.solutions-section.visualisation.title',
        description:
            'view-devops-consulting-services.solutions-section.visualisation.desc',
    },
    {
        icon: <AccessionIcon width={42} height={42} />,
        text: 'view-devops-consulting-services.solutions-section.accession.title',
        description:
            'view-devops-consulting-services.solutions-section.accession.desc',
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    showAllArticles: true,
    tags: [BLOG_POST_TAGS.SOFTWARE_ARCHITECTURE, BLOG_POST_TAGS.CLOUD_DEVOPS],
    button: {
        to: `${PATHS.BLOG}/${PATHS.JAVASCRIPT}`,
        label: 'view-devops-consulting-services.footer.button',
    },
    categoryBanner: {
        title: 'view-devops-consulting-services.footer.title',
        description: 'view-devops-consulting-services.footer.description',
        svg: JavascriptSvg,
    },
};

export const useCaseStudiesData = () => {
    const images = useStaticQuery(graphql`
        {
            awsCostOptimization: file(
                relativePath: { eq: "case-studies/aws-cost-optimization.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            churn: file(
                relativePath: { eq: "case-studies/churn-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return [
        {
            title: 'case-studies-page.awsCostOptimization',
            field: 'case-studies-page.field.healthcare',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.awsCostOptimization.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.AWS_COST_OPTIMIZATION}`,
        },
        {
            title: `case-studies-page.churn`,
            field: `case-studies-page.field.dataAnalysis`,
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.DATA_ANALYSIS,
                CASE_STUDIES_TAG.DATA_SCIENCE,
            ],
            image: images.churn.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.CUSTOMER_CHURN_PREDICTION_SOFTWARE}`,
        },
    ];
};
